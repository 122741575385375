import React, { PureComponent, Fragment } from "react";

import "../styles/main.scss";

import Seo from "../components/Seo";
import Dalia from "../components/ClientPages/Dalia";

export default class DaliaPage extends PureComponent {
  render() {
    return (
      <Fragment>
        <Seo title="Dalia" />
        <Dalia />
      </Fragment>
    );
  }
}
