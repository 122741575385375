import React, { PureComponent } from "react";

import styles from "./Main.module.scss";

import ClientBase from "./ClientBase";

import Video from "../../img/client_pages/dalia/video.mp4";
import Feature from "../../img/client_pages/dalia/feature.jpg";
import Design from "../../img/client_pages/dalia/design.png";
import Iphone from "../../img/client_pages/dalia/iphone.png";

export default class Dalia extends PureComponent {
  render() {
    return (
      <ClientBase
        mainImgStyle={styles.DaliaMainImg}
        video={Video}
        title="Dalia"
        theBrand={
          "Dalia Al Mahmood is a co-active coach. She helps her clients push past limitations and step into the most authentic versions of themselves."
        }
        ourWork={[
          "Brand Identity",
          "Website Design",
          "Social Style",
          "Social Strategy"
        ]}
        brandDesign={
          "Due to the personal nature of her work, we collaborated with Dalia to convey a sense of femininity, clarity, and just a bit of adventure. We communicated these key essences through mindful attention to color palette, brand voice, and imagery."
        }
        featureImg={Feature}
        webDesignText={
          "First, we built a clean, simple-to-use website for easy future maintenance. Prompted by Dalia’s mission to motivate her clients, we decided to incorporate small doses of inspiration throughout the site, alongside grounding in-depth information about co-active coaching."
        }
        webDesignImg={Design}
        socialDesignText={
          "Our goal for Dalia’s social media presence was to ensure the account was both personally significant and easy to maintain. To support the communication of Dalia’s coaching principles, as well as how she incorporated them into her own life, we merged on-brand visuals with personal photos."
        }
        socialDesignImg={Iphone}
      />
    );
  }
}
